import React, { Component } from 'react'
import ProductContent from './components/admin/Content/product/ProductContent'
import UserContent from './components/admin/Content/user/UserContent'
import OrderContent from './components/admin/Content/order/OrderContent'
import Footer from './components/admin/Footer/Footer'
import Header from './components/admin/Header/Header'
import Menu from './components/admin/Menu/Menu'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import ProductTypeContent from './components/admin/Content/product/ProductTypeContent'
import { Basket } from './components/user/Component/BasketButton'
import ProductList from './components/user/Component/ProductList'
import { BasketProvider } from './components/context/BasketContext';
import NotFound from './pages/NotFound';
import { UserHeader } from './components/user/Header'
import ProductTypeList from './components/user/Component/ProductTypeList'
import Checkout from './components/user/Content/Checkout'
import Savetobasket from './components/user/Content/Checkout/savetobasket.js'
import OrderReceived from './components/user/Content/OrderReceived'
import Login from './pages/Login'
import Register from './pages/Register'
import Logout from './pages/Logout'
import { UserContext } from './components/context/UserContext'
import { Order } from './components/user/Content/Order'
import Profile from './pages/Profile'
import ForgotPassword from './pages/ForgotPassword'
import { ExchangeProvider } from './components/context/ExchangeContext'
import Exchangee from './components/admin/Content/Exchange/ExchangeContent'
import BannerContent from './components/admin/Content/banner/BannerContent'
import HomePageTest from './components/user/Content/Home'
import Page2 from './components/admin/components/Page2'
import Admincards from './components/admin/admincards'
const NewHome = React.lazy(() => import('./components/user/Component/NewHome'));
const Card = React.lazy(() => import('./pages/Card'));
const CardAdmin = React.lazy(() => import('./pages/Card/admin'));
const CardAdminEdit = React.lazy(() => import('./pages/Card/adminedit'));
const GroupAdmin = React.lazy(() => import('./pages/Card/groupadmin'));
const Cardz = React.lazy(() => import('./pages/Card/card'));
const StyleOne = React.lazy(() => import('./pages/Card/style1'));
const Landing = React.lazy(()=>import('./pages/landing'))
const App = () => {

  const ctx = React.useContext(UserContext);
  return (
    <BrowserRouter>
      <React.Suspense fallback={<div></div>}>
        {/* admin */}
        {ctx.state.role == 1 ?
          ctx.state.loggin ?
            <div>
              <Switch>
                <Route path="/" exact>
                <Header />
                  <Menu />
                </Route>
                <Route path="/login" exact>
                  <Login />
                </Route>
                <Route path="/admin">
                  <Header />
                  <Menu />
                </Route>
                <Route path="/cardone/:username" component={StyleOne} exact />
                <Route path="/cardadd" component={CardAdmin} exact />
                <Route path="/cardedit/:username" component={CardAdminEdit} exact />
                <Route path="/groupedit/:name" component={GroupAdmin} exact />
                <Route path="/cardz/:username" component={StyleOne} exact />
                <Route path="/card/:username" component={StyleOne} exact />
                <Route component={NotFound} />
              </Switch>
              <Switch>
                <Route path="/" exact>
                  <OrderContent />
                </Route>
                <Route path="/admin" exact>
                  <OrderContent />
                </Route>
                <Route path="/admin/product" exact>
                  <ProductContent user={ctx.state.user} />
                </Route>
                <Route path="/admin/product/type" >
                  <ProductTypeContent />
                </Route>
                <Route path="/admin/orders">
                  <OrderContent />
                </Route>
                <Route path="/admin/users">
                  <UserContent />
                </Route>
                <Route path="/admin/exchange">
                  <Exchangee />
                </Route>
                <Route path="/admin/banner">
                  <BannerContent />
                </Route>
                <Route path="/admin/page2">
                  <Page2 />
                </Route>
                <Route path="/admin/cards">
                  <Admincards/>
                </Route>
              </Switch>
              <Switch>
                <Route path="/admin">
                  <Footer />
                </Route>
              </Switch>
              <Route path="/logout" exact>
                <Logout></Logout>
              </Route>
            </div> :
            <Switch>
              <Route path="/" exact>
                  <Landing/>
                </Route>
              <Route path="/login" exact>
                <Login />
              </Route>
              <Route path="/register" exact>
                <Register />
              </Route>

              <Route path="/cardone/:username" component={StyleOne} exact />
                <Route path="/cardadd" component={CardAdmin} exact />
                <Route path="/cardedit/:username" component={CardAdminEdit} exact />
                <Route path="/groupedit/:name" component={GroupAdmin} exact />
                <Route path="/cardz/:username" component={StyleOne} exact />
                <Route path="/card/:username" component={StyleOne} exact />

              <Redirect to="login"></Redirect>
              <Route component={NotFound} />
            </Switch>
          :
          <div>

            {/* user */}
            {ctx.state.loggin ?
              <ExchangeProvider>
                <BasketProvider>
                  <Switch>
                    <Route path="/login" exact>
                      <Login />
                    </Route>
                    <Route path="/cardone/:username" component={() => <div></div>} exact />
                    
                    <Route path="/cardadd" component={() => <div></div>} exact />
                    <Route path="/cardedit/:username" component={CardAdminEdit} exact />
                    <Route path="/groupedit/:name" component={GroupAdmin} exact />
                    <Route path="/cardz/:username" component={() => <div></div>} exact />
                    <Route path="/card/:username" component={() => <div></div>} exact />
                    
                    <Route path="">
                      <UserHeader />
                    </Route>

                  </Switch>
                  <Switch>
                    <Route path="/" exact>
                    <ProductTypeList />
                    <Basket />
                    </Route>
                    <Route path="/newhome" exact>
                    <ProductTypeList />
                    <Basket />
                    </Route>
                    <Route path="/home" exact>
                    <ProductTypeList />
                    <Basket />
                    </Route>
                    <Route path="/category" exact>
                      <ProductTypeList />
                      <Basket />
                    </Route>
                    <Route path="/YourOrder" exact>
                      <Order />
                    </Route>
                    <Route path="/order-received" exact>
                      <OrderReceived />
                    </Route>
                    <Route path="/checkout" exact>
                      <Checkout></Checkout>
                    </Route>
                    <Route path="/savetobasket" exact>
                      <Savetobasket></Savetobasket>
                    </Route>
                    <Route path="/productlist" exact>
                      <div>
                        <ProductList />
                        <Basket />
                      </div>
                    </Route>
                    <Route path="/profile" exact>
                      <Profile />
                    </Route>
                    <Route path="/logout" exact>
                      <Logout></Logout>
                    </Route>
                    <Route path="/cardone/:username" component={StyleOne} exact />
                    
                    <Route path="/cardone/:username" component={StyleOne} exact />
                <Route path="/cardadd" component={CardAdmin} exact />
                <Route path="/cardedit/:username" component={CardAdminEdit} exact />
                <Route path="/groupedit/:name" component={GroupAdmin} exact />
                <Route path="/cardz/:username" component={StyleOne} exact />
                <Route path="/card/:username" component={StyleOne} exact />
                    <Route component={NotFound} />
                  </Switch>
                </BasketProvider>
              </ExchangeProvider> :
              <Switch>

<Route path="/" exact>
  <Landing/>
</Route>
                <Route path="/login" exact>
                  <Login />
                </Route>
                <Route path="/register" exact>
                  <Register />
                </Route>
                <Route path="/forgot" exact>
                  <ForgotPassword></ForgotPassword>
                </Route>
                <Route path="/cardone/:username" component={StyleOne} exact />
                <Route path="/cardadd" component={CardAdmin} exact />
                <Route path="/cardedit/:username" component={CardAdminEdit} exact />
                <Route path="/groupedit/:name" component={GroupAdmin} exact />
                <Route path="/cardz/:username" component={StyleOne} exact />
                <Route path="/card/:username" component={StyleOne} exact />
                <Redirect to="login"></Redirect>
              </Switch>}
          </div>}

      </React.Suspense>
    </BrowserRouter>
  )
}
export default App
