import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CheckoutDetailPop from "../../Component/CheckoutDetailPop";
import { CheckoutDetail } from "../../Component/CheckoutDetail";
import CheckoutAddAddress from "../../Component/CheckoutAddAddress";
import CheckoutAddNumber from "../../Component/CheckoutAddNumber";
import { validation } from "../../../../util/Validator";
import { BasketContext } from "../../../context/BasketContext";
import { UserContext } from "../../../context/UserContext";
import firebase from "../../../../firebase";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import css from "./style.module.css";
import { stat } from "fs";
const Index = (props) => {
  const ctx = React.useContext(BasketContext);
  const ctxUser = React.useContext(UserContext);
  const objuser = JSON.parse(ctxUser.state.user);
  // Захиалга хийсний дараа баазад хадгалагдах бүтээгдэхүүн
  var updateproducts = [];
  // Захиалга хийхийн өмнөх захиалага дахт бүтээгдэхүүн
  var oldproducts = [];
  // console.log('ctxUser:',ctx.exchange);
  let defaultData = {
    data: {
      name: "",
      val: "",
      isupdate: false,
      key: "",
    },
    errorMsg: {
      name: { show: false },
      val: { show: false },
    },
  };
  // state
  const [data, setData] = React.useState({ ...defaultData.data });
  const [errorMsg, setErrorMsg] = React.useState({ ...defaultData.errorMsg });
  const [seladdress, setSelAddress] = React.useState(0);
  const [selnumber, setSelNumber] = React.useState(0);
  const [formValid, setFormValid] = React.useState(false);
  const [order, setOrder] = React.useState(null);
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    // user
    firebase
      .firestore()
      .collection("users")
      .where("email", "==", objuser.email)
      .get()
      .then((collections) => {
        const users = collections.docs.map((product) => product.data());
        if (users.length > 0) {
          setUser(users[0]);
          // console.log('test ctx:',ctx.exchange);
          setOrder({
            orderstatus: 0,
            userno: objuser.phoneNumber,
            email: users[0].email,
            rate: ctx.exchange,
            totalprice: ctx.totalprice,
            totalprice1: ctx.totalprice1,
            // totalprice2:ctx.totalprice2,
            totalpoint: ctx.totalpoint,
            deliveryaddress:
              users[0] && users[0].myaddresses
                ? users[0].myaddresses[0].val
                : null,
            deliverynumber:
              users[0] && users[0].mynumbers ? users[0].mynumbers[0].val : null,
            orderdetail: [
              ...ctx.basket.map((item) => ({
                id: item.id,
                name: item.name,
                quantity: item.qty,
                price: item.price,
                price1: item.price1,
                point: item.point,
              })),
            ],
          });
        }
        // console.log('users: ',users);
      });
    // order
  }, [ctx.basket]);
  // Хүргэх хаяг болон захиалгын утас хэсгийг солих
  const onChangeRadioValue = (event) => {
    let id = event.target.id.substr(event.target.id.length - 1, 1);
    let name = event.target.id.substr(0, event.target.id.length - 1);
    switch (name) {
      case "address":
        setSelAddress(id);
        setOrderData(user.myaddresses[id].val, null);
        break;
      case "number":
        setSelNumber(id);
        setOrderData(null, user.mynumbers[id].val);
        break;
    }
  };
  const sendSmsToAdmin = async (order) => {
    await firebase.firestore().collection('ordermessages').add({to:'+97696289060',body:`${order.totalprice}₮ дүнтэй захиалга шинээр бүртгэгдлээ`})
  }
  // шинэ хаяг нэмэх
  const addAddress = () => {
    setUser((curstate) => {
      const myaddresses = data.isupdate
        ? curstate.myaddresses.map((item, j) => {
            if (j === data.key) {
              return { name: data.name, val: data.val };
            } else {
              return item;
            }
          })
        : user.myaddresses
        ? [...user.myaddresses, { name: data.name, val: data.val }]
        : [{ name: data.name, val: data.val }];
      const sel = data.isupdate ? data.key : myaddresses.length - 1;
      setSelAddress(sel);
      setOrderData(data.val, null);
      return {
        ...user,
        myaddresses: myaddresses,
      };
    });
    setData(defaultData.data);
  };
  // шинэ дугаар нэмэх
  const addNumber = () => {
    setUser((curstate) => {
      // console.log("datas:",user.mynumbers)
      const mynumbers = data.isupdate
        ? curstate.mynumbers.map((item, j) => {
            if (j === data.key) {
              return { name: data.name, val: data.val };
            } else {
              return item;
            }
          })
        : user.mynumbers
        ? [...user.mynumbers, { name: data.name, val: data.val }]
        : [{ name: data.name, val: data.val }];
      const sel = data.isupdate ? data.key : mynumbers.length - 1;
      setSelNumber(sel);
      setOrderData(null, data.val);
      return {
        ...user,
        mynumbers: mynumbers,
      };
    });
    setData(defaultData.data);
  };
  // захиалгын мэдээлэл бөглөх
  const setOrderData = (selAdd, selNum) => {
    // console.log('order1: ',order);
    setOrder({
      ...order,
      deliveryaddress:
        selAdd == null ? order.deliveryaddress : selAdd == -1 ? null : selAdd,
      deliverynumber:
        selNum == null ? order.deliverynumber : selNum == -1 ? null : selNum,
    });
  };
  const delAddress = (key) => {
    user.myaddresses.splice(key, 1);
    setSelAddress(user.myaddresses.length - 1);
    setOrderData(
      user.myaddresses.length - 1 < 0
        ? -1
        : user.myaddresses[user.myaddresses.length - 1].val,
      null
    );
    setUser({ ...user });
  };
  const delNumber = (key) => {
    user.mynumbers.splice(key, 1);
    setSelNumber(user.mynumbers.length - 1);
    setOrderData(
      null,
      user.mynumbers.length - 1 < 0
        ? -1
        : user.mynumbers[user.mynumbers.length - 1].val
    );
    setUser({ ...user });
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const validate = validation(value);
    let formValid = false;
    if (!validate.show) {
      let istemvavlid = true;
      Object.values(data).map((el, index) => {
        if (
          ![2, 3].includes(index) &&
          el.length == 0 &&
          Object.keys(errorMsg)[index] != [name]
        ) {
          istemvavlid = false;
          return;
        }
      });
      if (istemvavlid) {
        formValid = true;
      }
    }
    setErrorMsg({
      ...errorMsg,
      [name]: validate,
    });
    setData({
      ...data,
      [name]: value,
    });
    setFormValid(formValid);
  };
  const setAddData = (opt) => {
    setErrorMsg({ ...defaultData.errorMsg });
    setData({ ...defaultData.data, name: opt == "number" ? "Гар Утас" : "" });
    setFormValid(false);
  };
  const setUpdateData = (data, key) => {
    setData({ ...data, isupdate: true, key: key });
    setFormValid(true);
  };
  const submitForm = () => {
    // console.log('test:',order);
    // if(1==1){
    //     alert("Түр хугацаанд захиалга хийгдэх боломжгүй");
    //     return false;
    // }
    if (order && order.orderdetail.length !== 0) {
      if (checkOrder()) {
        confirmAlert({
          title: "Баталгаажуулах",
          message: "Та үүнийг хийхдээ итгэлтэй байна уу?",
          buttons: [
            {
              label: "Тийм",
              onClick: () => {
                ctx.setIsLoading(true);
                checkOrderStatus();
              },
            },
            {
              label: "Үгүй",
              onClick: () => {},
            },
          ],
        });
      }
    } else {
      console.log("Бүтээгдэхүүн олдсонгүй");
      alert("Бүтээгдэхүүн олдсонгүй");
    }
  };
  // 1-р алхам захиалгын мэдээлэл шалгах
  const checkOrder = () => {
    // console.log('order.userno:',order.userno);
    if (!order.userno) {
      alert("Хэрэглэгчийн мэдээлэл олдсонгүй!");
      return false;
    } else if (!order.deliveryaddress) {
      alert("Хүргэх хаяг тодорхоогүй байна!");
      return false;
    } else if (!order.deliverynumber) {
      alert("Хүргэх утас тодорхоогүй байна!");
      return false;
    } else if (!order.rate || order.rate == -1) {
      alert("Ханш тодорхоогүй байна!");
      return false;
    } else if (!order.totalprice) {
      alert("Нийт үнэ тооцоологдоогүй байна!");
      return false;
    } else if (!order.totalpoint) {
      alert("Нийт оноо тооцоологдоогүй байна!");
      return false;
    } else {
      return true;
    }
  };
  // 2-р алхам Захиалга хийж болох эсхийг шалгах
  const checkOrderStatus = () => {
    let sfDocRef = firebase.firestore().collection("orderstatus").doc("1");
    firebase
      .firestore()
      .runTransaction((transaction) => {
        return transaction.get(sfDocRef).then((sfDoc) => {
          if (!sfDoc.exists) {
            setOrderStatus(
              {
                runing: false,
                userno: order.userno,
                lastdate: new Date().toString(),
              },
              1
            );
            throw "Document does not exist!";
          }
          if (sfDoc.data().runing) {
            const lastUpdatedData = sfDoc.data().lastdate;
            const date1 = new Date(lastUpdatedData);
            const date2 = new Date();
            const diffTime = Math.abs(date2 - date1);
            const diffSec = Math.round(diffTime / 1000);
            // console.log("sec", diffSec);
            if (diffSec < 10) {
              ctx.setIsLoading(false);
              alert("Та түр хүлээгээд дахин оролдоно уу");
              return false;
            } else {
              transaction.update(sfDocRef, {
                runing: true,
                userno: order.userno,
                lastdate: new Date().toString(),
              });
              checkProductRec(0);
            }
          } else {
            transaction.update(sfDocRef, {
              runing: true,
              userno: order.userno,
              lastdate: new Date().toString(),
            });
            checkProductRec(0);
          }
        });
      })
      // .then(() => {
      //     console.log("Transaction successfully committed!");
      // })
      .catch((error) => {
        ctx.setIsLoading(false);
        console.log("firebase error: alkham2 " + error);
        alert("Захиалаг хийхэд алдаа гарлаа дахин оролдоно уу");
        return false;
      });
  };
  // 3-р алхам Бааз дахь бүтээгдэхүүний үлдэгдэл одоогийхоор шалгах
  const checkProductRec = (start) => {
    let productArray = []; // захиалга дахь барааны дугаарууд
    let ischeck = true; // энэ үйлдэл амжилттай болох эсэх
    let islent = false;
    let deeplent = start + 9;
    if (deeplent >= order.orderdetail.length) {
      islent = true;
      deeplent = order.orderdetail.length;
    }
    for (let i = start; i < deeplent; i++) {
      // захиалга дахь барааны дугаарууд утга оноох
      productArray.push(order.orderdetail[i].id);
    }
    // Бүтээгдэхүүн шалгах
    firebase
      .firestore()
      .collection("product")
      .where("id", "in", [...productArray])
      .get()
      .then((querySnapshot2) => {
        let tempproduct;
        querySnapshot2.forEach(function (doc2) {
          if (doc2.data() != null && ischeck) {
            tempproduct = doc2.data();
            oldproducts.push(tempproduct);
            // order.orderdetail.map((item) => {
            //   if (item.id === tempproduct.id) {
            //     tempproduct.quantity -= item.quantity;
            //     if (tempproduct.quantity < 0) {
            //       ischeck = false;
            //       ctx.setIsLoading(false);
            //       alert("Агуулах дахь барааны үлдэгдэл хүрэлцэхгүй байна");
            //     }
            //   }
            // });
            updateproducts.push(tempproduct);
          }
        });
        if (ischeck) {
          if (islent) {
            setOrderToBase();
          } else {
            checkProductRec(start + 9);
          }
        } else {
          setOrderStatus(
            {
              runing: false,
              userno: order.userno,
              lastdate: new Date().toString(),
            },
            2
          );
        }
      })
      .catch((ex) => {
        ctx.setIsLoading(false);
        console.log("firebase error: alkham 3:", ex);
        alert("Захиалга хийхэд алдаа гарлаа. Алхам 3");
      });
  };
  const setOrderStatus = (orderstatus, alkham) => {
    firebase
      .firestore()
      .collection("orderstatus")
      .doc("1")
      .set(orderstatus)
      .catch((ex) => {
        ctx.setIsLoading(false);
        console.log("firebase error: alkham" + alkham + " " + ex);
        return false;
      });
  };
  // 4-р алхам баазруу захиалгаа хийх
  const setOrderToBase = () => {
    order.orderid = orderIdGen(); // захиалгийн дугаар үүсгэх
    order.orderdate = ordeDate(); // захиалгын өдөр үүсгэх
    order.sysdate = new Date().toString(); // захиалга хийсэн цаг
    let itemlen = order.orderdetail.length;
    console.log("order", order);
    firebase
      .firestore()
      .collection("order")
      .doc(order.orderid.toString())
      .set(order)
      .then((ref) => {
        sendSmsToAdmin(order);
        for (let i = 0; i < updateproducts.length; i++) {
          firebase
            .firestore()
            .collection("product")
            .doc(updateproducts[i].id)
            .set(updateproducts[i])
            .then(() => {
              if (i == updateproducts.length - 1) {
                // console.log('amjilttai: ');
                ctx.setIsLoading(false);
                alert("Амжилттай");
                setOrderStatus(
                  {
                    runing: false,
                    userno: order.userno,
                    lastdate: new Date().toString(),
                  },
                  3
                );
                
                props.history.push({
                  pathname: "/order-received",
                  state: {
                    orderid: order.orderid,
                    orderdate: order.orderdate,
                    totalprice: order.totalprice,
                    totalprice1: order.totalprice1,
                    // totalprice2: order.totalprice2,
                    deliveryaddress: order.deliveryaddress,
                    deliverynumber: order.deliverynumber,
                    itemlen: itemlen,
                  },
                });
                ctx.clearBasket();
                ctx.setShow(false);
              }
            })
            .catch((ex) => {
              ctx.setIsLoading(false);
              // файлруу бичих oldproducts, updateproducts ба i - алдаа гарсан бараа
              console.log("firebase error: updateproduct alkham5  " + ex);
              firebase
                .firestore()
                .collection("order")
                .doc(order.orderid)
                .delete()
                .then((res) => {
                  alert("Захиалга хийхэд алдаа гарлаа.[2]");
                })
                .catch((ex) => {
                  console.log("firebase error: deleteorder alkham4  " + ex);
                  // файлруу бичих order
                });
              return;
            });
        }
      })
      .catch((ex) => {
        ctx.setIsLoading(false);
        console.log("firebase error: alkham4 " + ex);
        alert("Захиалга хийхэд алдаа гарлаа. Алхам 4");
      });
    ctx.setIsLoading(false);
  };
  const orderIdGen = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    var tt = today.getHours();
    var min = today.getMinutes();
    var sec = today.getSeconds();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (min < 10) {
      min = "0" + min;
    }
    if (sec < 10) {
      sec = "0" + sec;
    }
    return "" + yyyy + mm + dd + tt + min + sec + order.userno;
  };
  const ordeDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return yyyy + "/" + mm + "/" + dd;
  };
  return (
    <div className={css.kqrKyZ}>
      {ctx.isloading ? (
        <div className={css.loader}></div>
      ) : (
        <div>
          <form>
            <div className={css.dcTgel}>
              <div className={css.hGqnHy}>
                <div className={css.hwlTmd}>
                  <CheckoutDetailPop
                    key="1"
                    ispbutton={false}
                    option={"address"}
                    data={user && user.myaddresses ? user.myaddresses : []}
                    sel={seladdress}
                    onChangeRadioValue={onChangeRadioValue}
                    setAdd={setAddData}
                    setUpdateData={setUpdateData}
                    delData={delAddress}
                  ></CheckoutDetailPop>
                  <CheckoutDetailPop
                    key="2"
                    ispbutton={submitForm}
                    option={"number"}
                    data={user && user.mynumbers ? user.mynumbers : []}
                    sel={selnumber}
                    onChangeRadioValue={onChangeRadioValue}
                    setAdd={setAddData}
                    setUpdateData={setUpdateData}
                    delData={delNumber}
                  ></CheckoutDetailPop>
                </div>
                <CheckoutDetail key={0} order={order}></CheckoutDetail>
              </div>
            </div>
          </form>
          <CheckoutAddAddress
            data={data}
            formValid={formValid}
            handleInputChange={handleInputChange}
            addfunc={addAddress}
          ></CheckoutAddAddress>
          <CheckoutAddNumber
            data={data}
            formValid={formValid}
            handleInputChange={handleInputChange}
            addfunc={addNumber}
          ></CheckoutAddNumber>
        </div>
      )}
    </div>
  );
};
export default withRouter(Index);
