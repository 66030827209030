import React, { Component } from 'react'
import css from './style.module.css'
import Page1 from '../../Component/Page1/index'
import Page2 from '../../Component/Page2/index'
import Page3 from '../../Component/Page3/index'
const index = (props) => {
    return (
        <div className={css.cont}>
            <div className={css.contdiv1}>
                <div>
                    <img src="/logo512.png"/>
                </div>
                <div>
                    <ul>
                        {/* <li>Видео</li> */}
                        <li>
                            <a href="/category">
                                <p>Захиалга</p>
                            </a>
                        </li>
                        <li>
                            <a href="/cardone">
                                <p>BeSmart карт</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default index