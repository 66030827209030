import {useEffect,useState,useRef} from 'react'
import firebase from 'firebase'
import css from "./style.module.css"
const Admincards = () => {
    const [loading,setLoading] = useState(true);
    const [cards,setcards] = useState();
    const [groups,setgroups] = useState([]);
    const [search,setsearch] = useState();

    const fetchdata = async () => {
    setLoading(true);
    const arr = [];
    const arr2 = [];
    const snapshot = await firebase.firestore().collection('cards').get()
    const snapshot2 = await firebase.firestore().collection('groups').get()
    snapshot.docs.map(doc => arr.push(doc.data()));
    snapshot2.docs.map(doc => arr2.push(doc.data()));
    setcards(arr);
    setgroups(arr2);
    }

    const addtoGroup = async (username,group,userdata) => {
        if(group != "false" && group != "newgroup"){
            let userData = userdata;
            userData.group = group;
            await firebase.firestore().collection('cards').doc(username).set(userData)
            alert("Амжилттай")
        }

        if(group == "newgroup") {
            const newgroupname = prompt("Бүлгийн нэр оруулна уу");
            const admin = prompt("Бүлэг удирдах username үүсгэнэ үү");
            const pass = prompt("Бүлэг удирдах нууц үг үүсгэнэ үү");
            if(groups.find(g=>g.name == newgroupname || g.admin == admin) == undefined) {
                let cover= `https://firebasestorage.googleapis.com/v0/b/onlineshop-29d11.appspot.com/o/${newgroupname}groupcvr?alt=media`;
                let video = ``;
                await firebase.firestore().collection('groups').doc(newgroupname).set({name:newgroupname,video:video,cvr:cover,admin:admin,pass:pass});
                let userData = userdata;
                userData.group = newgroupname;
                await firebase.firestore().collection('cards').doc(username).set(userData)
                alert("Амжилттай")
            } else {
                alert("Бүлэг давхцаж байна")
            }
        }
      
    }

    const operations = (btnId, iss, card) => {
       if(document.getElementById('select' + card.username).value == "pwreset"){

          let pass1 = prompt("4 оронтой нууц үг үүсгэнэ үү...");
          let pass2 = prompt("Давтан оруулна уу...");

          if(pass1 == pass2){
            card.password = pass1.toString();
            firebase.firestore().collection('cards').doc(card.username).set(card).then((res) => {
                alert("Амжилттай");
                window.location.reload();
            }).catch(ex => {
                console.log("servertei holbogdhod " + ex);
                alert("servertei hobogdhod aldaa garlaa", ex);
            })
          } else {
            alert("Нууц үг хоорондоо таарсангүй...");
            window.location.reload();
          }

       } else {let ids = document.getElementById(btnId);
        if (iss) {
            if (card.status != document.getElementById('select' + card.username).value)
                ids.style.display = 'block';
            else
                ids.style.display = 'none';
        }
        else {
            let stat = document.getElementById('select' + card.username).value == "true" ? true : false;
            card.status = stat;
            // console.log("changed user", user)
            firebase.firestore().collection('cards').doc(card.username).set(card).then((res) => {
                ids.style.display = 'none';
                window.location.reload();
            }).catch(ex => {
                console.log("servertei holbogdhod " + ex);
                alert("servertei hobogdhod aldaa garlaa", ex);
            })

        }
    }}

    useEffect(()=>{
       (async () => {
       await fetchdata();
       setLoading(false);
       })()
    },[])

  return (
    <div className="content-wrapper">
    <div className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                
            </div>
        </div>
    </div>
    <section className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    {
                        loading ? <div>Loading</div> : <div className="card">
                        <div className="card-header" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <h3 className="card-title">Картуудын жагсаалт</h3>
                            <input type="number" onChange={(e)=>setsearch(e.target.value.toString())} placeholder = "Дугаараар хайх..."/>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body" >
                            <table id="example1" className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Овог нэр</th>
                                        <th>Утасны дугаар</th>
                                        <th>Айди</th>
                                        <th>Үүссэн</th>
                                        <th>Төлөв</th>
                                        <th>Групп</th>
                                    </tr>
                                </thead>
                                <tbody>
            
                                    {!search ? cards.map((el, index) => (
                                        <tr key={index}>
                                            <td>  {(el.lname && el.fname) && (el.lname + " овогтой " + el.fname)}</td>
                                            <td>  {el.phone && el.phone}</td>
                                            <td>  {el.username}</td>
                                            <td>  {el.created ? el.created : 'Хуучин'}</td>
                                            <td>
                                                <div className={css.divBtnHide}>
                                                    <select id={'select' + el.username} className={(el.status == false ?css.colorred:css.colorgreen )+" form-control"} onChange={() => operations('hideBtn' + el.username, true, el)}>
                                                        <option value={el.status} selected>{el.status == false ? "Эрх хаалттай" : "Эрх нээлттэй"}</option>
                                                        {el.status == false && <option value="true">Эрх нээх</option>}
                                                        {el.status == true && <option value="false">Эрх хаах</option>}
                                                        <option value="pwreset">Нууц үг шинээр үүсгэх</option>
                                                    </select>
                                                    <button id={'hideBtn' + el.username} onClick={() => operations('hideBtn' + el.username, false, el)} type="button" className={css.btnHidden}><i className='fa fa-check'></i></button>
                                                </div>
                                            </td>
                                            <td>
                                            <select className={"form-control"} onChange={(e) => addtoGroup(el.username, e.target.value,el)}>
                                                {el.group ? <option value={el.group}>{el.group}</option> : <option value={"false"}>Бүлэггүй</option>}
                                                       {groups.length > 0 && groups.map((g)=><option value={g.name}>{g.name}</option>)}
                                                       <option value={"newgroup"}>Шинэ бүлэг нүүсгэх</option>
                                            </select>
                                            </td>
                                        </tr>
                                    )) : cards.map((el, index) => (
                                        (el.phone && el.phone.toString().includes(search)) && <tr key={index}>
                                            <td>  {(el.lname && el.fname) && (el.lname + " овогтой " + el.fname)}</td>
                                            <td>  {el.phone && el.phone}</td>
                                            <td>  {el.username}</td>
                                            <td>  {el.created ? el.created : 'Хуучин'}</td>
                                            <td>
                                                <div className={css.divBtnHide}>
                                                    <select id={'select' + el.username} className={(el.status == false ?css.colorred:css.colorgreen )+" form-control"} onChange={() => operations('hideBtn' + el.username, true, el)}>
                                                        <option value={el.status} selected>{el.status == false ? "Эрх хаалттай" : "Эрх нээлттэй"}</option>
                                                        {el.status == false && <option value="true">Эрх нээх</option>}
                                                        {el.status == true && <option value="false">Эрх хаах</option>}
                                                        <option value="pwreset">Нууц үг шинээр үүсгэх</option>
                                                    </select>
                                                    <button id={'hideBtn' + el.username} onClick={() => operations('hideBtn' + el.username, false, el)} type="button" className={css.btnHidden}><i className='fa fa-check'></i></button>
                                                </div>
                                            </td>
                                            <td>
                                            <select className={"form-control"} onChange={(e) => addtoGroup(el.username, e.target.value,el)}>
                                                {el.group ? <option value={el.group}>{el.group}</option> : <option value={"false"}>Бүлэггүй</option>}
                                                       {groups.length > 0 && groups.map((g)=><option value={g.name}>{g.name}</option>)}
                                                       <option value={"newgroup"}>Шинэ бүлэг үүсгэх</option>
                                            </select>
                                            </td>
                                        </tr>
                                    ))}

                                    
            
                                </tbody>
                                <tfoot>
                                <tr>
                                        <th>Овог нэр</th>
                                        <th>Утасны дугаар</th>
                                        <th>Айди</th>
                                        <th>Үүссэн</th>
                                        <th>Төлөв</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        {/* /.card-body */}
                    </div>
                    }
                </div>
            </div>
        </div>
    </section>
</div>
  )
}

export default Admincards