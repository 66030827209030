import React from 'react'

import firebase from "../../../../firebase";
import css from './style.module.css'
import cssLoading from '../ProductList/style.module.css'
import { Link, withRouter } from 'react-router-dom'

const Index = () => {
    const [page2, setPage2] = React.useState([]);
    const [loading, setLoading] = React.useState([]);
    React.useEffect(() => {
        setLoading(true);
        firebase.firestore().collection('page2').doc("1").get().then((page2Data) => {
            setPage2(page2Data.data());
            setLoading(false);
        })
    }, []);
    return (
        loading ? <div className={cssLoading.loader2}></div>: <Link to = "/category" > <img src = {page2.image} className = { css.dHQHGmImg } /></Link>

    )
}

export default withRouter(Index);