import React, { useState } from 'react';
import css from './style.module.css'
import { validation } from '../../util/Validator'
import LoginComponent from '../../components/user/Component/LoginComponent'
import { Redirect, withRouter } from 'react-router-dom'
import { UserContext } from '../../components/context/UserContext'
const Index = () => {
    const ctx = React.useContext(UserContext);
    const status = ctx.state.status;
    const [login, setLogin] = useState({
        email: '',
        password: '',
    });
    const [errorMsg, setErrorMsg] = useState({
        email: { show: false },
        password: { show: false },
    });
    const [formValid, setFormValid] = useState(false);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const type = target.type;
        const validationTypes = {
            price: 'POSITIVENUMBER',
            id: 'EQUAL'
        }

        const validate = validation(value, validationTypes[name], name == 'id');

        let formValid = false;

        if (!validate.show) {
            let istemvavlid = true;
            Object.values(login).map((el, index) => {
                if (el.length == 0 && Object.keys(login)[index] != [name]) {
                    istemvavlid = false;
                    return;
                }
            })
            if (istemvavlid) {
                formValid = true;
            }
        }


        setErrorMsg(prevState => ({
            ...prevState,
            [name]: validate
        }));
        setLogin(prevState => ({
            ...prevState,
            [name]: value
        }));
        setFormValid(formValid)
    }
    const submitData = () => {
        let email = login.email;
        const password = login.password;
        const returnSecureToken = true;
        if (email == 'admin01') {
            email = 'admin01@gmail.com'
        }
        else if(email == 'admin1') {
            email = 'admin@email.com'
        }
        const data = {
            email, password, returnSecureToken
        }
        ctx.signInUser(data);
    }
    // console.log("ctx", status)
    return (
        ctx.state.loggin ? ctx.state.role == 1 ? window.location.href='/admin' : window.location.href='/' :
            ctx.state.loading ? <div className={css.loader}></div> :
                <LoginComponent status={status} firebaseError={ctx.state.signInerror} formValid={formValid} handleInputChange={handleInputChange} errorMsg={errorMsg} submitData={submitData}></LoginComponent>
    )
}

export default withRouter(Index);